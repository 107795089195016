<template>
  <v-row :justify="sentByMe ? 'end' : 'start'" class="px-4 py-2 my-2">
    <v-col cols="12" class="py-0 px-2">
      <h5
        v-if="sentByMe"
        style="font-weight: 400; text-align: right"
        class="pl-3 pb-0"
      >
        <timestamp-formatter
          format="date-time"
          :model-value="message.createdAt"
        />
        <v-icon
          v-if="message.isTemplate"
          size="small"
          color="primary"
          :icon="mdiFileDocument"
        />
        <chat-message-email-indicator
          v-if="message.email || message.carrierEmail"
          :full-email="message.fullEmail"
          :message-id="message.id"
        />
        <app-button
          v-if="canDelete"
          color="error"
          size="small"
          density="compact"
          variant="text"
          :icon="mdiDelete"
          data-testid="delete-message"
          @click="destroy"
        />
      </h5>
      <div v-else>
        <h5 style="font-weight: 400" class="pb-0">
          <template v-if="message.ownable">
            {{ message.ownable.name }}
            <timestamp-formatter
              format="date-time"
              :model-value="message.createdAt"
            />
          </template>
          <chat-message-email-indicator
            v-if="message.email || message.carrierEmail"
            :full-email="message.fullEmail"
            :message-id="message.id"
          />
          <app-button
            v-if="canDelete"
            color="error"
            :icon="mdiDelete"
            size="small"
            density="compact"
            variant="text"
            data-testid="delete-message"
            @click="destroy"
          />
        </h5>
        <h5 v-if="message.inboundEmail" class="pb-0" style="font-weight: 400">
          Replied from {{ message.inboundEmail }}
        </h5>
      </div>
    </v-col>
    <div
      style="display: flex; flex-direction: column"
      :style="{
        width: usingIframe ? '100%' : null,
        maxWidth: maxWidth
      }"
      class="px-2"
    >
      <div style="width: 100%">
        <v-card
          class="pa-3 chat-message-card"
          :data-testid="sentByMe ? 'sent-message' : 'received-message'"
          :color="sentByMe ? 'primary' : 'secondary'"
          :class="{
            'sent-by-me': sentByMe,
            'bottom-right-point': sentByMe,
            'sent-by-other': !sentByMe,
            'bottom-left-point': !sentByMe
          }"
        >
          <template v-if="usingIframe">
            <div>
              <v-tooltip location="bottom">
                <template #activator="{ props: templateProps }">
                  <app-button
                    v-bind="templateProps"
                    :icon="mdiArrowExpandAll"
                    variant="text"
                    density="comfortable"
                    color="black"
                    style="position: absolute; top: 15px; right: 15px"
                    @click="showIframeFullscreen"
                  />
                </template>
                <span>View Fullscreen</span>
              </v-tooltip>
              <div class="frame-wrap">
                <template-renderer
                  class="frame"
                  :model-value="message.html || message.note"
                />
              </div>
            </div>
          </template>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-else-if="message.isTemplate"
            :class="{ 'opacity-80': !sentByMe }"
            class="mb-0 template-message better-line-height"
            v-html="sanitize(message.note)"
          />
          <div
            v-else-if="message.html"
            :class="{ lighter: !sentByMe }"
            class="mb-0 plain-message better-line-height"
            v-html="sanitize(message.html)"
          />
          <include-links-in-text
            v-else-if="message.note"
            :text="message.note"
            :darker="sentByMe"
            class="white-space-pre-wrap mb-0 plain-message better-line-height"
          />
          <a
            v-for="(document, index) in message.documents"
            :key="index"
            class="message-link text-decoration-underline clickable ma-1"
            style="cursor: pointer"
            v-bind="download(document)"
          >
            {{ document.name }}
            <span v-if="document.fileSize">({{ document.fileSize }})</span>
          </a>
        </v-card>
      </div>
    </div>
  </v-row>
</template>

<script setup>
import TemplateRenderer from "@/components/shared/TemplateRenderer.vue";
import IncludeLinksInText from "@/components/shared/IncludeLinksInText.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import ChatMessageEmailIndicator from "@/components/shared/chat/ChatMessageEmailIndicator.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import ChatTemplateViewer from "@/dialogs/ChatTemplateViewer.vue";

import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";
import { sanitize } from "@/html-sanitizer";
import { getDocumentUrl } from "@/api/documents.service";
import { deleteNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, onBeforeUnmount, toRefs, markRaw } from "vue";

import { mdiFileDocument, mdiDelete, mdiArrowExpandAll } from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";

const props = defineProps({ message: { type: Object, default: () => {} } });
const emit = defineEmits(["remove-message"]);
const { smAndDown, mdAndDown } = useDisplay();

const user = useUserStore();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const { message } = toRefs(props);
const usingIframe = computed(() => message.value?.isTemplate);

const maxWidth = computed(() => {
  if (smAndDown.value) return "95%";
  return usingIframe.value ? "50%" : "70%";
});

const sentByMe = computed(() => {
  const sameId = message.value?.ownable?.id === user.loginable.id;
  const sameType = message.value?.ownable?.type === user.loginable.type;
  return sameId && sameType;
});

const canDelete = computed(() => {
  if (!message.value.id) return false;
  if (sentByMe.value) return true;
  return user.isGroupTwoPlus;
});

onBeforeUnmount(() => dialog.closeDialog());

function destroy() {
  dialog.showDialog({
    component: ConfirmationDialog,
    title: "Delete Note",
    subtitle: "Delete this note?",
    func: async () => {
      try {
        await deleteNote(message.value.id);
        emit("remove-message", message.value);
      } catch (e) {
        snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    }
  });
}

function download({ uid }) {
  return downloadFileAsLink(getDocumentUrl(uid), mdAndDown.value);
}

function showIframeFullscreen() {
  dialog.showDialog({
    component: markRaw(ChatTemplateViewer),
    message: message.value,
    width: "100%"
  });
}
</script>
