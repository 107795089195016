import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/idp";
export async function firelightSamlResponse() {
  const { data } = await getHttpClient().get(`${baseUrl}/firelight`);

  return {
    samlUrl: data.saml_url,
    samlResponse: data.saml_response
  };
}
