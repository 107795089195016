<template>
  <v-card class="pt-6 pa-3 bg-surface" flat variant="outlined">
    <div class="px-4 justify-center flex-row">
      <v-img
        v-if="theme.current.value.dark"
        key="dark"
        :src="darkImage"
        height="100"
        style="max-width: 80%"
        contain
      />
      <v-img
        v-else
        key="light"
        :src="image"
        height="100"
        style="max-width: 80%"
        contain
      />
    </div>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text :data-testid="`${title}-subtitle`" class="pb-0">
      {{ subtitle }}
    </v-card-text>
    <v-card-text>
      <v-row v-if="solid" class="ma-0 flex-nowrap" align="center">
        <div class="mr-3" :data-testid="`${title}-body`">
          {{ text }}
        </div>
        <app-button
          :data-testid="`${title}-delete`"
          :icon="mdiDelete"
          color="error"
          class="mb-1"
          variant="text"
          density="comfortable"
          @click="onClick()"
        />
      </v-row>
      <div v-else>{{ text }}</div>
    </v-card-text>
    <v-card-actions v-if="!solid">
      <app-button
        :data-testid="`${title}-action`"
        class="text-none"
        color="primary"
        variant="text"
        density="comfortable"
        :text="actionText"
        @click="onClick()"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { mdiDelete } from "@mdi/js";

import { useTheme } from "vuetify/lib/framework.mjs";

defineProps({
  solid: Boolean,
  image: { type: String, default: "", required: false },
  darkImage: { type: String, default: "", required: false },
  title: { type: String, default: "", required: false },
  text: { type: String, default: "", required: false },
  subtitle: { type: String, default: "", required: false },
  onClick: { type: Function, default: () => {}, required: false },
  actionText: { type: String, default: "", required: false }
});

const theme = useTheme();
</script>
