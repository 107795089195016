<template>
  <v-card class="confirmation-dialog">
    <v-card-title data-testid="title"> {{ title }} </v-card-title>
    <v-card-subtitle
      class="pt-3"
      style="white-space: pre-line"
      data-testid="subtitle"
    >
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text v-if="html && prewrap" data-testid="prewrap-html">
      <pre>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="sanitize(html)" />
      </pre>
    </v-card-text>
    <v-card-text v-else-if="html" data-testid="html" :class="htmlWrapperClass">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="sanitize(html)"></div>
    </v-card-text>
    <v-card-actions class="px-5">
      <checkbox-field
        v-if="checkboxText"
        v-model="checkboxChecked"
        data-testid="checkbox"
        :label="checkboxText"
        dense
      />
      <v-spacer />
      <app-button
        v-if="!hideCancel"
        variant="outlined"
        class="text-none"
        data-testid="cancel-button"
        :disabled="loading"
        @click="cancelAction"
      >
        {{ cancelText ? cancelText : "Cancel" }}
      </app-button>
      <app-button
        v-if="warningText"
        class="text-none"
        color="warning"
        data-testid="warning-button"
        :disabled="loading"
        @click="warningAction"
      >
        {{ warningText }}
      </app-button>

      <confirmation-dialog-confirm-button
        v-if="secondaryText"
        :loading="loading"
        :confirm-href="secondaryHref || ''"
        data-testid="secondary-button"
        @click="confirmAction(true)"
      >
        {{ secondaryText }}
      </confirmation-dialog-confirm-button>
      <confirmation-dialog-confirm-button
        :loading="loading"
        :confirm-href="confirmHref || ''"
        data-testid="confirm-button"
        @click="confirmAction(false)"
      >
        {{ confirmText || "Confirm" }}
      </confirmation-dialog-confirm-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import ConfirmationDialogConfirmButton from "@/dialogs/ConfirmationDialogConfirmButton.vue";

import { sanitize } from "@/html-sanitizer";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref } from "vue";

const props = defineProps({
  title: { type: String, default: null },
  subtitle: { type: String, default: null },

  confirmText: { type: String, default: null },
  confirmHref: { type: String, default: null, required: false },
  func: { type: Function, default: () => {} },

  secondaryText: { type: String, default: null },
  secondaryHref: { type: String, default: null },
  secondaryFunc: { type: Function, default: () => {} },

  checkboxText: { type: String, default: null },
  cancelText: { type: String, default: null },
  hideCancel: Boolean,
  html: { type: String, default: null },
  prewrap: Boolean,
  warningText: { type: String, default: null },
  htmlWrapperClass: { type: String, default: null }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const loading = ref(false);
const checkboxChecked = ref(false);
function warningAction() {
  dialog.closeDialog({ warning: true });
}
async function confirmAction(secondary = false) {
  const body = { confirm: true, secondary };
  if (props.checkboxText) {
    body.checkboxChecked = checkboxChecked.value;
  }

  let func = props.func;
  if (secondary) func = props.secondaryFunc;

  if (!func) return dialog.closeDialog(body);

  loading.value = true;
  try {
    await func(body);
    dialog.closeDialog(body);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
function cancelAction() {
  dialog.closeDialog({
    checkboxChecked: checkboxChecked.value,
    confirm: false
  });
}
</script>
