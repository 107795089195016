import RedtailIntegrationDialog from "@/components/settings/security/RedtailIntegrationDialog.vue";
import { deleteIdentity } from "@/api/security.service";
import format from "date-fns/format";
import { useDialogStore } from "@/stores/dialog";
import { markRaw } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import {
  executeWealthboxSso,
  executeMicrosoftSso
} from "@/components/settings/security/sso.composable";

import microsoft from "@/assets/img/microsoft.svg";
import wealthboxDark from "@/assets/img/wealthbox-dark.png";
import wealthbox from "@/assets/img/wealthbox.png";
import redtail from "@/assets/img/Redtail.svg";

export const useMicrosoftIntegration = integration => {
  const data = {
    title: "Microsoft",
    darkImage: microsoft,
    image: microsoft,
    subtitle:
      "Sign-in with one click using your Microsoft account. No need to remember another password.",
    text: null,
    onClick: () => {
      const dialog = useDialogStore();
      return dialog.showDialog({
        component: markRaw(ConfirmationDialog),
        title: "Connect to Microsoft",
        subtitle:
          'Please confirm your intent to connect to Microsoft.  By clicking "Confirm" You will be redirected to Microsoft.',
        func: executeMicrosoftSso
      });
    },
    actionText: "Get Started",
    solid: false
  };

  if (integration.enabled) {
    data.solid = true;
    data.actionText = "Delete Connection";
    const connected = format(new Date(integration.createdAt), "MM/dd/yyyy");
    data.text = `You connected to Microsoft on ${connected}. `;
    data.onClick = () => {
      const dialog = useDialogStore();
      dialog.showDialog({
        component: markRaw(ConfirmationDialog),
        title: "Delete Connection",
        subtitle:
          "Please confirm your intent to delete the connection to Microsoft.",
        func: async () => {
          await deleteIdentity(integration.id);
          integration.enabled = false;
          integration.id = null;
        }
      });
    };
  }

  return data;
};

export const useWealthboxIntegration = integration => {
  const data = {
    title: "Wealthbox",
    darkImage: wealthboxDark,
    image: wealthbox,
    subtitle:
      "Improve your efficiency with our Wealthbox integration. Launch Quote & Apply directly from Wealthbox CRM, using your clients' existing information to prefill electronic applications. Automatically sync your contacts from Boss into Wealthbox, reducing manual data entry and saving you time—so you can focus on growing your business.",
    text: null,
    actionText: "Get Started",
    onClick: (
      subtitle = 'Please confirm your intent to connect to Wealthbox.  By clicking "Confirm" You will be redirected to Wealthbox.'
    ) => {
      const dialog = useDialogStore();
      return dialog.showDialog({
        component: markRaw(ConfirmationDialog),
        title: "Connect to Wealthbox",
        subtitle,
        func: executeWealthboxSso
      });
    },
    solid: false
  };

  if (integration.enabled) {
    data.actionText = "Delete Connection";
    data.solid = true;
    const connected = format(new Date(integration.createdAt), "MM/dd/yyyy");
    data.text = `You connected to Wealthbox on ${connected}. `;
    data.onClick = () => {
      const dialog = useDialogStore();
      return dialog.showDialog({
        component: markRaw(ConfirmationDialog),
        title: "Delete Connection",
        subtitle:
          "Please confirm your intent to delete the connection to Wealthbox.",
        func: async () => {
          await deleteIdentity(integration.id);
          integration.enabled = false;
          integration.id = null;
        }
      });
    };
  }

  return data;
};

export const useRedtailIntegration = integration => {
  const data = {
    title: "Redtail",
    darkImage: redtail,
    image: redtail,
    subtitle:
      "Effortlessly manage your clients and insurance cases with our seamless Redtail integration. Keep your contacts and cases in sync, so you can easily access client information and related insurance policies within Redtail. Seperately, add your NPN within Redtail so you can launch Quote & Apply and automatically prefill the eApp with your client's information.",
    text: null,
    actionText: "Get Started",
    onClick: () => {
      const dialog = useDialogStore();
      return dialog.showDialog({
        component: markRaw(RedtailIntegrationDialog)
      });
    },
    solid: false
  };

  if (integration.enabled) {
    data.solid = true;
    const connected = format(new Date(integration.createdAt), "MM/dd/yyyy");
    data.text = `You connected to Redtail on ${connected}. `;
    data.actionText = "Delete Connection";
    data.onClick = () => {
      const dialog = useDialogStore();
      dialog.showDialog({
        component: markRaw(ConfirmationDialog),
        title: "Delete Connection",
        subtitle:
          "Please confirm your intent to delete the connection to Redtail.",
        func: async () => {
          await deleteIdentity(integration.id);
          integration.enabled = false;
          integration.id = null;
        }
      });
    };
  }
  return data;
};
