<template>
  <div>
    <template v-for="(t, index) in splitText" :key="index">
      <a v-if="t.isLink" :href="t.text" target="_blank">{{ t.text }}</a>
      <span
        v-else
        :class="{ 'opacity-80': !darker, 'opacity-100': fullOpacity }"
        >{{ t.text }}</span
      >
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    required: true
  },
  darker: Boolean,
  fullOpacity: Boolean
});

const urlRegex = new RegExp(/(https:\/\/[^\s"]+)/g);

const splitText = props.text
  .split(urlRegex)
  .map(t => ({ isLink: urlRegex.test(t), text: t }));
</script>
