<template>
  <v-card flat color="section">
    <v-card-title>SSO</v-card-title>
    <v-card-text>
      <v-row class="ma-0">
        <v-col
          v-for="partner in partners"
          :key="`${partner.text}`"
          cols="12"
          lg="4"
          md="6"
        >
          <security-action-card v-bind="partner" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import SecurityActionCard from "@/components/settings/security/SecurityActionCard.vue";
import { useMicrosoftIntegration } from "@/components/settings/security/integration-partners.composable";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";

const { integrations } = storeToRefs(useUserStore());

const partners = computed(() => {
  return [useMicrosoftIntegration(integrations.value.microsoft)];
});
</script>
