<template>
  <v-card flat color="section">
    <v-card-title> Payment Method </v-card-title>
    <v-card-text> Select a Payment Method to receive commissions. </v-card-text>
    <v-card-text>
      <v-row class="ma-0">
        <select-field
          v-model="activeEft"
          item-title="payingTo"
          item-value="id"
          label="Payment Method"
          :prepend-inner-icon="mdiCheckbook"
          style="max-width: 30rem"
          data-testid="payment-method-selector"
          :items="eftOptions"
          :success="activeEftValidation.success"
          :error-messages="activeEftValidation.errorMessages"
          :disabled="props.readonly"
        >
          <template #append-inner>
            <active-save-indicator
              :controller="savingBuffer.activeEft.controller.value"
            />
          </template>
        </select-field>
        <app-button
          v-if="canDownloadEft"
          :icon="mdiDownload"
          data-testid="payment-method-download"
          color="primary"
          variant="text"
          target="_blank"
          v-bind="downloadEft"
        />
      </v-row>
      <app-button
        class="text-none"
        color="accent"
        variant="elevated"
        data-testid="add-payment-method"
        @click="addNewEft"
      >
        <v-icon :icon="mdiPlus" /> Add Payment Method
      </app-button>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import AddEftDialog from "@/dialogs/AddEftDialog.vue";
import { downloadFileAsLink } from "@/util/helpers";
import { getDocumentUrl } from "@/api/documents.service";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useSettingsViewStore } from "@/stores/settings-view";
import { computed, markRaw } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { mdiCheckbook, mdiDownload, mdiPlus } from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";

const props = defineProps({
  readonly: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;

if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const { efts } = storeToRefs(store);

const dialog = useDialogStore();

const savingBuffer = {
  activeEft: useActiveSave()
};

const user = useUserStore();

const eftOptions = computed(() => Object.values(efts.value));

const activeEft = computed({
  get() {
    return activeEftObject.value?.id;
  },
  set(eftId) {
    if (props.readonly) return;
    store.updateActiveEft(eftId);
  }
});

const activeEftObject = computed(() =>
  eftOptions.value.find(val => val.current === 1)
);

const canDownloadEft = computed(() => {
  if (user.isGroupOne || props.readonly) return false;
  return Boolean(efts.value[activeEft.value]?.eftDocument?.uid);
});

const activeEftValidation = computed(() => {
  const required = Boolean(activeEftObject.value);
  const errorMessages = [];
  if (!required) errorMessages.push("Required");
  if (activeEftObject.value?.errors) {
    errorMessages.push(
      "The provided EFT is invalid. Please provide a valid EFT."
    );
  }
  if(!activeEftObject.value?.eftDocument) {
    errorMessages.push("A check is not the preferred payment method")
  }
  return { success: !errorMessages.length, errorMessages };
});

const { mdAndDown } = useDisplay();
const downloadEft = computed(() => {
  return downloadFileAsLink(
    getDocumentUrl(efts.value[activeEft.value].eftDocument.uid),
    mdAndDown.value
  );
});

function addNewEft() {
  dialog.showDialog({
    component: markRaw(AddEftDialog),
    module: props.module,
    scrollable: true
  });
}
</script>
