<template>
  <v-navigation-drawer v-model="show" app width="300" color="sidebar">
    <v-col align="center">
      <v-row justify="center" align="center" class="ma-0">
        <v-icon
          size="40"
          color="rgb(159,201,182)"
          style="padding-top: 2px"
          :icon="mdiShieldAccount"
        />
        <app-logo />
      </v-row>
    </v-col>
    <div v-if="mdAndDown" class="ma-1">
      <app-button
        class="text-none"
        :to="backRoute"
        exact
        color="primary"
        block
        variant="outlined"
      >
        <v-icon :icon="mdiArrowLeft" /> Back to BOSS
      </app-button>
    </div>
    <v-skeleton-loader v-if="loading" type="list-item@13" />
    <v-list v-else density="compact" nav>
      <v-list-subheader v-if="showSubheaders">
        Agent Settings
      </v-list-subheader>
      <v-list-item
        v-for="link in agentPages"
        :key="link.text + 'component'"
        link
        :data-testid="`link-${link.text}`"
        :class="{ 'bg-secondary': link.page === activeTab }"
        @click="selectTab(link.page)"
      >
        <template #prepend>
          <v-icon :icon="link.icon" />
        </template>

        <v-list-item-title> {{ link.text }} </v-list-item-title>

        <template #append>
          <v-fade-transition mode="out-in">
            <v-icon
              v-if="agent.pageValidation[link.page] === false"
              key="invalid"
              data-testid="error-icon"
              color="error"
              class="opacity-100"
              :icon="mdiAlertCircle"
            />
            <v-icon
              v-else-if="
                !agent.pageValidation[link.page] ||
                agent.pageValidation[link.page] === true
              "
              key="valid"
              color="success"
              class="opacity-100"
              data-testid="success-icon"
              :icon="mdiCheck"
            />
          </v-fade-transition>
        </template>
      </v-list-item>
      <template v-if="showSubheaders">
        <v-list-subheader v-if="showSubheaders">
          Agency Settings
        </v-list-subheader>
        <select-field
          v-if="Object.values(agent.agencies).length > 1"
          v-model="settings.currentAgencyId"
          solo
          dense
          hide-details
          item-title="name"
          item-value="id"
          class="mb-1"
          :items="Object.values(agent.agencies)"
          @update:model-value="fetchCurrentAgency"
        />
      </template>
      <v-list-item
        v-for="link in agencyPages"
        :key="link.text + 'component'"
        link
        :data-testid="`link-${link.text}`"
        :class="{ 'bg-secondary': link.page === activeTab }"
        @click="selectTab(link.page)"
      >
        <template #prepend>
          <v-icon :icon="link.icon" />
        </template>

        <v-list-item-title> {{ link.text }} </v-list-item-title>

        <template #append>
          <v-fade-transition mode="out-in">
            <v-icon
              v-if="agency.pageValidation[link.page] === false"
              key="invalid"
              color="error"
              data-testid="error-icon"
              :icon="mdiAlertCircle"
              class="opacity-100"
            />
            <v-icon
              v-else-if="
                !agency.pageValidation[link.page] ||
                agency.pageValidation[link.page] === true
              "
              key="success"
              color="success"
              data-testid="success-icon"
              :icon="mdiCheck"
              class="opacity-100"
            />
          </v-fade-transition>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <template v-if="mdAndDown">
      <v-app-bar absolute flat color="sidebar">
        <h1
          v-if="name"
          class="text-lg-h5 text-md-h6 text-sm-h6 text-h6 pl-4 text-center"
        >
          {{ name }}'s Settings
        </h1>
      </v-app-bar>
      <v-divider />
      <v-app-bar app location="bottom" color="sidebar" class="pa-0">
        <v-row style="height: 100%">
          <v-col cols="2" class="pa-0">
            <app-button
              style="width: 100%; height: 100%"
              variant="text"
              @click="show = !show"
            >
              <v-icon :icon="mdiMenu" />
            </app-button>
          </v-col>
          <v-spacer />
          <v-col cols="10" class="pa-0">
            <v-row class="ma-0 px-3" style="height: 100%">
              <v-spacer />
              <app-button
                v-if="previousPage"
                class="text-none mr-1"
                variant="text"
                :style="{ minWidth: showMobileNavStep ? '150px' : null }"
                style="height: 100%"
                @click="selectTab(previousPage.page)"
              >
                <v-icon class="mr-1" :icon="mdiArrowLeft" />
                <template v-if="showMobileNavStep">
                  {{ previousPage.text }}
                </template>
              </app-button>
              <app-button
                v-if="nextPage"
                class="text-none ml-1"
                variant="text"
                style="height: 100%"
                :style="{ minWidth: showMobileNavStep ? '150px' : null }"
                @click="selectTab(nextPage.page)"
              >
                <template v-if="showMobileNavStep">
                  {{ nextPage.text }}
                </template>
                <v-icon class="ml-1" :icon="mdiArrowRight" />
              </app-button>
            </v-row>
          </v-col>
        </v-row>
      </v-app-bar>
    </template>

    <template v-else>
      <v-app-bar
        absolute
        flat
        class="mx-0 mb-3 px-3"
        align="center"
        color="sidebar"
        height="69"
        elevate-on-scroll
      >
        <app-button
          variant="outlined"
          class="text-none"
          :to="backRoute"
          exact
          color="primary"
        >
          <v-icon :icon="mdiArrowLeft" /> Back to BOSS
        </app-button>
        <h1 v-if="name" class="text-lg-h5 text-md-h6 text-sm-h6 text-h6 pl-3">
          {{ name }}'s Settings
        </h1>
      </v-app-bar>
      <v-divider />
    </template>

    <template v-if="loading">
      <v-skeleton-loader type="card" class="mb-2" />
      <v-skeleton-loader type="card" class="mb-2" />
      <v-skeleton-loader type="card" />
    </template>
    <div
      v-for="link in componentPages"
      v-show="!loading && activeTab === link.page"
      :key="link.text"
      class="pa-0"
    >
      <component
        :is="link.component"
        :key="link.text + 'component'"
        editing
        style="overflow: auto"
        class="settings-component"
        :module="link.module"
        :signature-ready="agent.readyForSignature"
        :is-viewing-self="isViewingSelf"
        @mounted="handleMounted(link.page)"
        @refresh="refresh"
        @go-to="goToTab"
      >
        <v-footer
          v-if="lgAndUp"
          tile
          color="transparent"
          width="100%"
          class="py-3"
        >
          <app-button
            v-if="previousPage"
            class="text-none"
            variant="outlined"
            @click="selectTab(previousPage.page)"
          >
            <v-icon class="mr-1" :icon="mdiArrowLeft" />
            {{ previousPage.text }}
          </app-button>
          <v-spacer />
          <app-button
            v-if="nextPage"
            class="text-none"
            variant="outlined"
            @click="selectTab(nextPage.page)"
          >
            {{ nextPage.text }}
            <v-icon class="ml-1" :icon="mdiArrowRight" />
          </app-button>
        </v-footer>
      </component>
    </div>
  </v-main>
</template>
<script setup>
import AppLogo from "@/components/AppLogo.vue";
import AdminSettings from "@/components/settings/admin/AdminSettings.vue";
import AgentBasicInfo from "@/components/settings/account-details/AgentBasicInfo.vue";
import SecuritySettings from "@/components/settings/security/SecuritySettings.vue";
import EmailSettings from "@/components/settings/email/EmailSettings.vue";
import TrainingsView from "@/components/settings/training/TrainingsView.vue";
import AgentCommissions from "@/components/settings/commissions/AgentCommissions.vue";
import EoPage from "@/components/settings/eo/EoPage.vue";
import EmploymentHistory from "@/components/settings/employment/EmploymentHistory.vue";
import ContractingQuestions from "@/components/settings/background-questions/ContractingQuestions.vue";
import AgentSignature from "@/components/settings/AgentSignature.vue";
import AgencyBasicInfo from "@/components/settings/account-details/AgencyBasicInfo.vue";
import AgencyCommissions from "@/components/settings/commissions/AgencyCommissions.vue";
import DeveloperSettings from "@/components/settings/developer/DeveloperSettings.vue";

import { useUserStore } from "@/stores/user";
import { useInstanceStore } from "@/stores/instance";
import { useSettingsViewStore } from "@/stores/settings-view";
import { useAgentSettingsStore, AGENT_SETTINGS } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { computed, onBeforeUnmount, ref, watch, nextTick } from "vue";
import { useRouter } from "vue-router";

import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";

import { useHead } from "@unhead/vue";
import {
  mdiShieldAccount,
  mdiArrowLeft,
  mdiAlertCircle,
  mdiCheck,
  mdiMenu,
  mdiArrowRight,
  mdiAccountMultiple,
  mdiAccountDetails,
  mdiEmail,
  mdiFileEye,
  mdiCurrencyUsd,
  mdiFile,
  mdiBriefcaseVariant,
  mdiGavel,
  mdiDraw,
  mdiSecurity,
  mdiCodeTags,
  mdiDomain
} from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useWealthboxIntegration } from "@/components/settings/security/integration-partners.composable";

const AGENT_ADMIN = {
  icon: mdiAccountMultiple,
  component: AdminSettings,
  text: "Admin",
  page: "admin",
  module: AGENT_SETTINGS,
  order: 1
};

const AGENT_ACCOUNT_DETAILS = {
  icon: mdiAccountDetails,
  component: AgentBasicInfo,
  text: "Account Details",
  page: "account-details",
  module: AGENT_SETTINGS,
  order: 2,
  defaultPage: true,
  previouslyKnownAs: ["basics", "profile"]
};

const AGENT_EMAIL_SETTINGS = {
  icon: mdiEmail,
  component: EmailSettings,
  text: "Email",
  page: "email",
  module: AGENT_SETTINGS,
  order: 3
};

const AGENT_TRAININGS = {
  icon: mdiFileEye,
  component: TrainingsView,
  text: "Training (Optional)",
  page: "trainings",
  module: AGENT_SETTINGS,
  order: 4
};

const AGENT_COMMISSIONS = {
  icon: mdiCurrencyUsd,
  component: AgentCommissions,
  text: "Commission",
  page: "commissions",
  module: AGENT_SETTINGS,
  order: 5,
  previouslyKnownAs: ["commission", "business"]
};

const AGENT_EO = {
  icon: mdiFile,
  component: EoPage,
  text: "E&O",
  page: "eo",
  module: AGENT_SETTINGS,
  order: 6
};

const AGENT_EMPLOYMENT = {
  icon: mdiBriefcaseVariant,
  component: EmploymentHistory,
  text: "Employment",
  page: "employment",
  module: AGENT_SETTINGS,
  order: 7
};

const AGENT_QUESTIONS = {
  icon: mdiGavel,
  component: ContractingQuestions,
  text: "Background Questions",
  page: "background-questions",
  module: AGENT_SETTINGS,
  order: 8,
  previouslyKnownAs: ["legal"]
};

const AGENT_SIGNATURE = {
  icon: mdiDraw,
  component: AgentSignature,
  text: "Signature",
  page: "signature",
  module: AGENT_SETTINGS,
  order: 9
};

const AGENT_SECURITY = {
  icon: mdiSecurity,
  component: SecuritySettings,
  text: "Security & Applications",
  page: "security",
  module: AGENT_SETTINGS,
  order: 10
};

const AGENT_DEVELOPER_SETTINGS = {
  icon: mdiCodeTags,
  component: DeveloperSettings,
  text: "Developer Settings",
  page: "developer-settings",
  module: AGENT_SETTINGS,
  order: 11
};

// Agency tabs
const AGENCY_ADMIN = {
  icon: mdiAccountMultiple,
  component: AdminSettings,
  text: "Agency Admin",
  page: "agency-admin",
  module: AGENCY_SETTINGS,
  order: 1
};

const AGENCY_ACCOUNT_DETAILS = {
  icon: mdiDomain,
  component: AgencyBasicInfo,
  text: "Agency Account Details",
  page: "agency-basics",
  module: AGENCY_SETTINGS,
  order: 3,
  defaultPage: true
};

const AGENCY_COMMISSIONS = {
  icon: mdiCurrencyUsd,
  text: "Agency Commissions",
  component: AgencyCommissions,
  page: "agency-commissions",
  module: AGENCY_SETTINGS,
  order: 4
};

// Not needed if viewing via agent
const AGENCY_EO = {
  icon: mdiFile,
  text: "Agency E&O",
  component: EoPage,
  page: "agency-eo",
  module: AGENCY_SETTINGS,
  order: 5
};

const AGENCY_EMAIL = {
  icon: mdiEmail,
  text: "Agency Email",
  component: EmailSettings,
  page: "agency-email",
  module: AGENCY_SETTINGS,
  order: 6
};

// Not needed if viewing via agent
const AGENCY_SECURITY = {
  icon: mdiSecurity,
  component: SecuritySettings,
  text: "Security & Applications",
  page: "agency-security",
  module: AGENCY_SETTINGS,
  order: 7
};

const AGENCY_DEVELOPER_SETTINGS = {
  icon: mdiCodeTags,
  component: DeveloperSettings,
  text: "Agency Developer Settings",
  page: "agency-developer-settings",
  module: AGENCY_SETTINGS,
  order: 8
};

const props = defineProps({
  id: { type: Number, required: true },
  page: { type: String, required: false, default: null },
  type: {
    type: String,
    required: true,
    validates: v => ["Agent", "Agency"].includes(v)
  },
  action: {
    type: String,
    required: false,
    default: null
  }
});

const { mdAndDown, lgAndUp } = useDisplay();

const loading = ref(false);
const activeTab = ref(null);

const show = ref(lgAndUp.value);

watch(lgAndUp, v => (show.value = Boolean(v)));

const instance = useInstanceStore();
const settings = useSettingsViewStore();
const user = useUserStore();
const agent = useAgentSettingsStore();
agent.$reset();

const router = useRouter();
const isViewingSelf =
  props.id === user.loginable.id && props.type === user.loginable.type;
let backRoute;
if (isViewingSelf) {
  backRoute = {
    name: "LoggedInUserProfile"
  };
} else {
  backRoute = {
    name: props.type === "Agent" ? "AgentView" : "AgencyView",
    params: {
      id: props.id
    }
  };
}

// const isEditable = isViewingSelf || user.isGroupTwoPlus;

const { smAndUp } = useDisplay();
const showMobileNavStep = computed(() => smAndUp.value);

const name = computed(() => {
  if (props.type === "Agency") return agency.value?.name;
  if (!agent.firstName) return null;
  return agent.name;
});

const agency = computed(() => {
  if (!settings.currentAgencyId) return null;
  return useAgencySettingsStore(settings.currentAgencyId);
});

const showSubheaders = computed(() => {
  return agent.id && settings.currentAgencyId;
});

const agentPages = computed(() => {
  if (!agent.id) return [];
  const links = [
    AGENT_ADMIN,
    AGENT_EMAIL_SETTINGS,
    AGENT_TRAININGS,
    AGENT_ACCOUNT_DETAILS,
    AGENT_DEVELOPER_SETTINGS
  ];

  if (isViewingSelf) links.push(AGENT_SECURITY);

  if (!user.loginable.hide_commission || !isViewingSelf) {
    links.push(AGENT_COMMISSIONS);
  }

  if (!agent.isSaas) {
    links.push(AGENT_EO, AGENT_EMPLOYMENT, AGENT_QUESTIONS, AGENT_SIGNATURE);
  }

  links.sort((a, b) => a.order - b.order);

  return links;
});
// We need to keep the sidebar ,but not
const agencyPages = computed(() => {
  if (!settings.currentAgencyId) return [];
  const links = [
    AGENCY_ADMIN,
    AGENCY_ACCOUNT_DETAILS,
    AGENCY_EMAIL,
    AGENCY_COMMISSIONS,
    AGENCY_DEVELOPER_SETTINGS
  ];

  if (props.type === "Agency") {
    if (isViewingSelf) links.push(AGENCY_SECURITY);
    links.push(AGENCY_EO);
  } else if (agent.assignment.id !== settings.currentAgencyId) {
    links.push(AGENCY_EO);
  }
  links.sort((a, b) => a.order - b.order);

  return links;
});

const componentPages = computed(() => {
  const pages = [];
  if (agentPages.value) pages.push(...agentPages.value);
  // Only return the components when the agency has loaded. this keeps the sidebar in tact
  if (agencyPages.value && agency.value?.id) pages.push(...agencyPages.value);
  return pages;
});

const currentIndex = computed(() => {
  return componentPages.value.findIndex(l => l.page === activeTab.value);
});

const previousPage = computed(() => {
  if (currentIndex.value - 1 < 0) return null;
  return componentPages.value[currentIndex.value - 1];
});

const nextPage = computed(() => {
  if (currentIndex.value + 1 >= componentPages.value.length) return null;
  return componentPages.value[currentIndex.value + 1];
});

function goToTab(page) {
  const link = componentPages.value.find(l => l.page === page);
  if (!link) return;
  selectTab(link.page);
}

async function selectTab(page) {
  activeTab.value = page;
  if (!router) return;
  if (router.currentRoute.value.query?.page === page) return;
  router.replace({ query: { page } });
}

function getQueryMatchedPage() {
  if (!router) return null;
  if (!props.page) return null;

  const queryMatched = componentPages.value.find(
    ({ page, previouslyKnownAs }) => {
      if (!page) return false;
      if (page === props.page) return true;
      if (!previouslyKnownAs?.length) return false;
      return previouslyKnownAs.includes(props.page);
    }
  );

  return queryMatched?.page;
}

function getFirstInvalidPage() {
  return componentPages.value.find(({ page, module }) => {
    if (module === AGENT_SETTINGS) return agent.pageValidation[page] === false;
    if (!agency.value?.pageValidation) return false;
    return agency.value.pageValidation[page] === false;
  })?.page;
}

function getFirstDefaultTab() {
  const firstDefault = componentPages.value.find(v => v?.defaultPage);
  return firstDefault?.page;
}

function goToLandingTab() {
  // check for query first
  const queryMatchedPage = getQueryMatchedPage();
  const firstInvalidPage = getFirstInvalidPage();

  let page = getFirstDefaultTab();
  if (queryMatchedPage) page = queryMatchedPage;
  else if (firstInvalidPage) page = firstInvalidPage;

  selectTab(page);
}

async function fetchCurrentAgency() {
  try {
    await settings.loadCurrentAgency();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    router.replace({ name: "Home" });
  }
}

const title = computed(() => {
  return `${name.value || "Advisor"} Settings`;
});

useHead({
  title
});

const snackbar = useSnackbarStore();
async function getAgentData(id) {
  try {
    loading.value = true;
    await agent.getAgentContractingData(id);
    instance.breadcrumb = name.value;

    const isSigner =
      agent.assignment.type === "Agency" && agent.agencies[agent.assignment.id];
    if (isSigner) {
      settings.currentAgencyId = agent.assignment.id;
      await fetchCurrentAgency();
    } else if (Object.values(agent.agencies).length) {
      const [firstAgency] = Object.values(agent.agencies);
      settings.currentAgencyId = firstAgency.id;
      await fetchCurrentAgency();
    }
    await waitForAllMounted();
    await nextTick();
    goToLandingTab();
    handleActions();

    loading.value = false;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    router.replace({ name: "Home" });
  }
}

async function getAgencyData(id) {
  try {
    loading.value = true;
    settings.currentAgencyId = id;
    await agency.value.getAgencyContractingData(id);
    instance.breadcrumb = name.value;
    await waitForAllMounted();
    await nextTick();
    goToLandingTab();
    loading.value = false;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    router.replace({ name: "Home" });
  }
}

let mountedResolve;
function waitForAllMounted() {
  return new Promise(r => {
    if (allCheck()) r();
    else mountedResolve = r;
  });
}

const mountedPages = {};
function allCheck() {
  let allMounted = true;
  [...agentPages.value, ...agencyPages.value].forEach(({ page }) => {
    if (!mountedPages[page]) {
      allMounted = false;
    }
  });
  return allMounted;
}

function handleMounted(page) {
  mountedPages[page] = true;

  if (allCheck() && mountedResolve) mountedResolve();
}

if (props.type === "Agent") {
  getAgentData(props.id);
} else {
  getAgencyData(props.id);
}

async function refresh() {
  const agent = useAgentSettingsStore();
  if (agent.id) await agent.getAgentContractingData(agent.id);

  if (settings.currentAgencyId) {
    const agency = useAgencySettingsStore(settings.currentAgencyId);
    await agency.getAgencyContractingData(agency.id);
  }
}

function handleActions() {
  if (
    isViewingSelf &&
    props.action === "connect_with_wealthbox" &&
    !user.integrations.wealthbox?.enabled
  ) {
    useWealthboxIntegration(user.integrations.wealthbox).onClick(
      'Looks like you are not currently connected to Wealthbox. To get started with this integration, please press "Confirm" to continue.'
    );
  }
}

onBeforeUnmount(() => {
  settings.currentAgencyId = null;
  instance.breadcrumb = "";
});
</script>

<style lang="scss">
.settings-component {
  .v-card-title {
    padding-top: 16px;
  }
}
</style>
