import { uuidv4, convertFileToBase64 } from "@/util/helpers";

export function NewCase(model = {}) {
  return {
    files: model?.files || [],
    applicationSignDate: model?.applicationSignDate || null,
    submittedDate: model?.submittedDate || null,
    approvedDate: model?.approvedDate || null,
    issueDate: model?.issueDate || null,
    policyDate: model?.policyDate || null,
    carrier: model?.carrier || null,
    product: model?.product || null,
    room: model?.room || [],
    contractParties: model?.contractParties || [],
    policyNumber: model?.policyNumber || "Pending",
    status: model?.status || null,
    state: model?.state || null,
    temporaryInsurance: model?.temporaryInsurance || null,
    premium: model?.premium || null,
    mode: model?.mode || null,
    benefitAmount: model?.benefitAmount || null,
    benefitPeriod: model?.benefitPeriod || null,
    benefitMode: model?.benefitMode || null,
    eliminationPeriod: model?.eliminationPeriod || null,
    inflation: model?.inflation || null,
    taxType: model?.taxType || null,
    faceAmount: model?.faceAmount || null,
    billingPlan: model?.billingPlan || null,
    cashReceived: model?.cashReceived || null,
    lumpSum: model?.lumpSum || null,
    exchangeAmount: model?.exchangeAmount || null,
    targetPremium: model?.targetPremium || null,
    ltcRider: model?.ltcRider || false
  };
}

export async function newCaseToRequest(newCase) {
  const isLTC = newCase.product.line === "ltc";
  const isAnnuity = newCase.product.line === "annuity";
  const isDisability = newCase.product.line === "disability";
  const isLinkedBenefit = newCase.product.line === "linked_benefit";

  const body = {
    app_sign_date: newCase.applicationSignDate,
    submitted_date: newCase.submittedDate,
    approved_date: newCase.approvedDate,
    issue_date: newCase.issueDate,
    policy_date: newCase.policyDate,
    billing_plan: newCase.billingPlan,
    cash_received: newCase.cashReceived,
    face_amount: newCase.faceAmount,
    lump_sum: newCase.lumpSum,
    mode: newCase.mode,
    policy_number: newCase.policyNumber,
    premium: newCase.premium,
    product_id: newCase.product.id,
    state: newCase.state,
    status: newCase.status,
    exchange_amount: newCase.exchangeAmount,
    appointment_cases_attributes: [],
    case_accesses_attributes: [],
    files: undefined,
    roles_attributes: [],
    target_premium: undefined,
    tax_type: undefined,
    tia: newCase.temporaryInsurance,
    inflation: undefined,
    benefit_period: undefined,
    benefit_amount: undefined,
    elimination_period: undefined,
    benefit_mode: undefined,
    ltc_rider: undefined
  };

  if (isAnnuity) {
    body.tax_type = newCase.taxType || undefined;
  } else {
    body.target_premium = newCase.targetPremium || undefined;
  }

  if (isDisability || isLTC || isLinkedBenefit) {
    body.inflation = newCase.inflation || undefined;
    body.benefit_period = newCase.benefitPeriod || undefined;
    body.benefit_amount = newCase.benefitAmount || undefined;
    body.elimination_period = newCase.eliminationPeriod || undefined;
  }

  if (isLTC) {
    body.benefit_mode = newCase.benefitMode || undefined;
  }

  if (newCase.product.ltc_rider) {
    body.ltc_rider = Boolean(newCase.ltcRider);
  }

  for (let i = 0; i < newCase.files.length; i++) {
    if (!body.files) body.files = [];
    const result = await convertFileToBase64(newCase.files[i]);
    body.files.push(result);
  }

  newCase.contractParties.forEach(
    ({
      roles,
      party,
      relationship,
      underwritingRationale,
      ratingApproved,
      ratingApplied,
      occupationClass,
      beneficiaryAmount
    }) => {
      roles.forEach(role => {
        let roleBody = {
          role,
          contract_party_id: party.id,
          relationship,
          underwriting_rationale: undefined,
          approval_rating: undefined,
          rating_applied: undefined,
          occupation_class: undefined,
          beneficiary_qualifier: undefined,
          beneficiary_amount: undefined
        };

        if (role.includes("Insured") || role.includes("Annuitant")) {
          roleBody.underwriting_rationale = underwritingRationale;
          roleBody.approval_rating = ratingApproved;
          roleBody.rating_applied = ratingApplied;
        }

        if (isDisability) {
          roleBody.occupation_class = occupationClass;
        }

        if (
          role.includes("Primary Beneficiary") ||
          role.includes("Contingent Beneficiary")
        ) {
          roleBody.beneficiary_qualifier = "Percent";
          roleBody.beneficiary_amount = +beneficiaryAmount / 100;
        }

        body.roles_attributes.push(roleBody);
      });
    }
  );

  newCase.room.forEach(advisor => {
    body.appointment_cases_attributes.push({
      appointment_id: advisor.code.id,
      marketing_manager_id: advisor.code.marketing_manager_id,
      renewal_split: Number(advisor.renewalSplitPercentage) / 100,
      split_percent: Number(advisor.splitPercentage) / 100
    });
  });

  newCase.room.forEach(({ advisor }) => {
    body.case_accesses_attributes.push({
      agent: 1,
      ownable_id: advisor.id,
      ownable_type: advisor.type?.model || advisor.type
    });
  });

  return body;
}

export function NewCaseAdvisor(model = {}) {
  return {
    key: uuidv4(),
    id: model?.id || 0,
    code: model?.code || null,
    renewalSplitPercentage: model?.renewalSplitPercentage || undefined,
    splitPercentage: model?.splitPercentage || undefined,
    advisor: model?.advisor || {},
    carrier: model?.carrier || {}
  };
}

export function NewCaseContractParty(model = {}) {
  return {
    key: uuidv4(),
    ratingApplied: model?.ratingApplied || null,
    ratingApproved: model?.ratingApproved || null,
    underwritingRationale: model?.underwritingRationale || null,
    party: model?.party || null,
    beneficiaryAmount: model?.beneficiaryAmount || null,
    occupationClass: model?.occupationClass || null,
    roles: model?.roles || [],
    relationship: model?.relationship || null
  };
}
