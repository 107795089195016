<template>
  <v-row dense class="ma-0">
    <v-col v-for="item in resources" :key="item.text" cols="12" md="3" sm="6">
      <quote-method v-bind="item" />
    </v-col>
    <v-col cols="12">
      <questionnaires-table class="pa-3" />
    </v-col>
  </v-row>
</template>

<script setup>
import QuoteMethod from "@/components/quotes/QuoteMethod.vue";
import QuestionnairesTable from "@/components/underwriting/QuestionnairesTable.vue";
import { mdiFilePdfBox } from "@mdi/js";

import ipipeline from "@/assets/img/ipipeline.png";
import firelight from "@/assets/img/firelight.svg";

import { executeFirelightSSO } from "@/components/settings/security/sso.composable";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { markRaw } from "vue";

import { useDialogStore } from "@/stores/dialog";
import { useUserStore } from "@/stores/user";

const dialog = useDialogStore();

const user = useUserStore();

const approvedDomainUrl = user.loginable.approved_domain_url;

const iGoEappLink =
  "https://pipepasstoigo.ipipeline.com/default.aspx?gaid=2479";

const iGoEappItem = {
  dataTestid: "iGo",
  text: "iGo eApp",
  type: "Life",
  description:
    "Log-in to iPipeline's eApp. Requires a BackNine iPipeline Account.",
  href: null,
  click: null,
  img: ipipeline
};

if (approvedDomainUrl) iGoEappItem.click = iPipelinePrompt;
else {
  iGoEappItem.href = iGoEappLink;
  iGoEappItem.target = "_blank";
}

function iPipelinePrompt() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Continue to iPipeline?",
    subtitle: "Some applications may be completed in Quote & Apply",
    confirmHref: iGoEappLink,
    confirmText: "Launch iGo",
    secondaryHref: approvedDomainUrl,
    secondaryText: "Launch Quote & Apply"
  });
}

function firelightPrompt() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Continue to Firelight?",
    subtitle: "Bridge to Hexure's Firelight to complete annuity eApplications",
    func: executeFirelightSSO
  });
}

const resources = [
  {
    text: "PDF Applications",
    type: "Annuity & Life",
    description:
      "Download PDFs for new business and policy holder services. Requires a BackNine iPipeline account.",
    img: ipipeline,
    href: "https://formspipe.ipipeline.com/?GAID=2479",
    target: "_blank"
  },
  {
    dataTestid: "firelight",
    text: "Firelight",
    type: "Annuity",
    description:
      "Bridge to Hexure's Firelight to complete annuity eApplications",
    img: firelight,
    click: firelightPrompt
  },
  {
    text: "Application Request",
    description: "Retrieve new application forms.",
    type: "All Lines",
    to: { name: "ApplicationRequest" },
    img: "https://portal-pictures.back9ins.com/BOSS-Logo.png"
  },
  {
    text: "Informal Inquiry",
    type: "Life",
    description:
      "Your client's medical records are sent to carriers for underwriting offers. Available for premiums $10,000+.",
    href: "https://cdn.back9ins.com/forms/Informal+Inquiry.pdf",
    target: "_blank",
    icon: mdiFilePdfBox,
    iconColor: "error"
  },
  {
    text: "Policy Review",
    type: "Annuity",
    description: "Used to obtain your client’s annuity policy information.",
    href: "https://cdn.back9ins.com/forms/Policy+Review+Authorization+-+Annuity.pdf",
    target: "_blank",
    icon: mdiFilePdfBox,
    iconColor: "error"
  },
  {
    text: "Policy Review",
    type: "Life",
    description: "Used to obtain your client’s life policy information.",
    href: "https://cdn.back9ins.com/forms/Policy+Review+Authorization+-+Life.pdf",
    target: "_blank",
    icon: mdiFilePdfBox,
    iconColor: "error"
  },
  iGoEappItem,
  {
    text: "Application",
    type: "Financed & Leverage Insurance",
    description: "Start a life settlement application.",
    href: "https://cdn.back9ins.com/forms/BackNine.Life.Settlement.Application.pdf",
    target: "_blank",
    icon: mdiFilePdfBox,
    iconColor: "error"
  },
  {
    text: "BackNine Preferred Agent Agreement",
    type: "All Lines",
    description:
      "Gain access to the ILIA platform by completing the Preferred Agent Agreement. This is needed for KaiZen and Premium Financed cases.",
    href: "https://cdn.back9ins.com/forms/KaiZen%20Preferred%20Agent%20Agreement%20&%20Rider.pdf",
    target: "_blank",
    icon: mdiFilePdfBox,
    iconColor: "error"
  }
];
</script>
